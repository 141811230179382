var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"multi-columns"},_vm._l((_vm.structure.rows),function(row,rowIndex){return _c('div',{key:rowIndex,staticClass:"row",class:{
			'gx-6 has-spacing': _vm.config.gutterSpacing,
			'reverse-column-mobile': _vm.config.reverseOnMobile === true,
		},attrs:{"data-aos":_vm.columnsAnimation,"data-aos-anchor-placement":"center-bottom","data-aos-duration":"1000"}},_vm._l((row.columns),function(column,columnIndex){return _c('div',{key:columnIndex,class:_vm.getColumnSeparationClasses(
				column.config
				? column.config.columnSeparations
				: null
			)},[_c('ce-renderer',{attrs:{"content":column.elements}})],1)}),0)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }