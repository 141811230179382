<template>
	<div class="multi-columns">
		<div
			class="row"
			v-for="(row, rowIndex) in structure.rows"
			:key="rowIndex"
			v-bind:class="{
				'gx-6 has-spacing': config.gutterSpacing,
				'reverse-column-mobile': config.reverseOnMobile === true,
			}"
			:data-aos="columnsAnimation"
			data-aos-anchor-placement="center-bottom"
			data-aos-duration="1000"
		>

			<!-- @TODO rewrite later to computed.classes() -->
			<div
				v-for="(column, columnIndex) in row.columns"
				:key="columnIndex"
				:class="getColumnSeparationClasses(
					column.config
					? column.config.columnSeparations
					: null
				)"
			>
				<ce-renderer
					:content="column.elements"
				/>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		structure: {
			type: Object,
			required: true
		}
	},
	methods: {
		getColumnSeparationClasses(config) {
			const classNames = [];

			if (config === null) {
				return classNames;
			}

			for (const breakpoint in config) {
				if (config[breakpoint] > 0) {
					classNames.push(`col-${breakpoint}-${config[breakpoint]}`);
				}
				else if (config[breakpoint] == 0) {
					classNames.push(`d-${breakpoint}-none`);
				}
			}
			return classNames;
		}
	},
	computed: {
		config () {
			return this.structure.rows[0].columns[0].config;
		},
		columnsAnimation () {
			if (this.config && this.config.animation) {
				return this.config.animation;
			}
			return '';
		},

		gutterSpacing () {
			if (this.config && this.config.gutterSpacing) {
				return this.config.gutterSpacing;
			}
			return '';
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
.multi-columns {

	.frame-default {
		padding: 0;
	}

	.reverse-column-mobile {
		@include media-breakpoint-down(md) {
			> div:first-child {
				order: 2;
			}
		}
	}

	.col-xs-12 {
		@include media-breakpoint-down(md) {
			margin-bottom: 1.5rem;
		}
	}

}
</style>
