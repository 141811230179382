const staticMarkers = [{
		"email": "dortmund@isd-service.de",
		"fax": "0231 33471730",
		"position": {
			"lat": "51.5197",
			"lng": "7.37284"
		},
		"nlCode": "50001",
		"ort": "Dortmund",
		"plz": "44379",
		"strasse": "Revierstr. 5",
		"telefon": "0231 3347170"
	},
	{
		"email": "duisburg@isd-service.de",
		"fax": "0203 9952130",
		"position": {
			"lat": "51.49219",
			"lng": "6.81478"
		},
		"nlCode": "50002",
		"ort": "Duisburg",
		"plz": "47167",
		"strasse": "Roentgenstrasse 10",
		"telefon": "0203 995210"
	}, {
		"email": "essen@isd-service.de",
		"fax": "0201 1056630",
		"position": {
			"lat": "51.44737",
			"lng": "6.99832"
		},
		"nlCode": "50003",
		"ort": "Essen",
		"plz": "45145",
		"strasse": "Kruppstrasse 82-100",
		"telefon": "0201 105660"
	}, {
		"email": "hamburg-jenfeld@isd-service.de",
		"fax": "040 66992930",
		"position": {
			"lat": "53.58056",
			"lng": "10.12422"
		},
		"nlCode": "50004",
		"ort": "Hamburg",
		"plz": "22045",
		"strasse": "Kuehnstrasse 71 c",
		"telefon": "040 6699290"
	}, {
		"email": "hannover@isd-service.de",
		"fax": "0511 21449630",
		"position": {
			"lat": "52.37314",
			"lng": "9.75834"
		},
		"nlCode": "50005",
		"ort": "Hannover",
		"plz": "30159",
		"strasse": "Bultstrasse 9",
		"telefon": "0511 2144960"
	}, {
		"email": "koeln@isd-service.de",
		"fax": "0221 95844830",
		"position": {
			"lat": "50.97833",
			"lng": "6.92094"
		},
		"nlCode": "50006",
		"ort": "Koeln",
		"plz": "50739",
		"strasse": "Robert-Perthel-Str. 3",
		"telefon": "0221 9584480"
	}, {
		"email": "frankfurt@isd-service.de",
		"fax": "069 42099330",
		"position": {
			"lat": "50.13255",
			"lng": "8.76586"
		},
		"nlCode": "50007",
		"ort": "Frankfurt",
		"plz": "60386",
		"strasse": "Hanauer Landstr. 551",
		"telefon": "069 4209930"
	}, {
		"email": "berlin-wedding@isd-service.de",
		"fax": "030 92706730",
		"position": {
			"lat": "52.57561",
			"lng": "13.34902"
		},
		"nlCode": "50008",
		"ort": "Berlin",
		"plz": "13407",
		"strasse": "Alt-Reinickendorf 29",
		"telefon": "030 9270670"
	}, {
		"email": "leipzig@isd-service.de",
		"fax": "034298 77530",
		"position": {
			"lat": "51.38177",
			"lng": "12.47804"
		},
		"nlCode": "50009",
		"ort": "Taucha",
		"plz": "04425",
		"strasse": "Am Obstgut 11",
		"telefon": "034298 7750"
	}, {
		"email": "stuttgart@isd-service.de",
		"fax": "0711 7871330",
		"position": {
			"lat": "48.72735",
			"lng": "9.11756"
		},
		"nlCode": "50010",
		"ort": "Stuttgart",
		"plz": "70565",
		"strasse": "Ruppmannstrasse 41",
		"telefon": "0711 787130"
	}, {
		"email": "muenchen-ost@isd-service.de",
		"fax": "089 67809330",
		"position": {
			"lat": "48.13399",
			"lng": "11.66778"
		},
		"nlCode": "50011",
		"ort": "Muenchen",
		"plz": "81829",
		"strasse": "Karl-Schmid-Str. 9",
		"telefon": "089 6780930"
	}, {
		"email": "mannheim@isd-service.de",
		"fax": "0621 7206330",
		"position": {
			"lat": "49.50816",
			"lng": "8.52166"
		},
		"nlCode": "50012",
		"ort": "Mannheim",
		"plz": "68309",
		"strasse": "Weinheimer Str. 58-60",
		"telefon": "0621 720630"
	}, {
		"email": "dresden@isd-service.de",
		"fax": "035204 96330",
		"position": {
			"lat": "51.03646",
			"lng": "13.59133"
		},
		"nlCode": "50013",
		"ort": "Kesselsdorf",
		"plz": "01723",
		"strasse": "Sachsenallee 9",
		"telefon": "035204 9630"
	}, {
		"email": "bielefeld@isd-service.de",
		"fax": "0521 9272630",
		"position": {
			"lat": "52.00776",
			"lng": "8.56081"
		},
		"nlCode": "50014",
		"ort": "Bielefeld",
		"plz": "33604",
		"strasse": "Otto-Brenner-Strasse 201",
		"telefon": "0521 927260"
	}, {
		"email": "berlin-wilmersdorf@isd-service.de",
		"fax": "030 89775630",
		"position": {
			"lat": "52.43754",
			"lng": "13.38839"
		},
		"nlCode": "50015",
		"ort": "Berlin",
		"plz": "12107",
		"strasse": "Mariendorfer Damm 161a",
		"telefon": "030 8977560"
	}, {
		"email": "bremen@isd-service.de",
		"fax": "0421 4304430",
		"position": {
			"lat": "53.06185",
			"lng": "8.87595"
		},
		"nlCode": "50016",
		"ort": "Bremen",
		"plz": "28207",
		"strasse": "Pfalzburger Strasse 85",
		"telefon": "0421 430440"
	}, {
		"email": "aachen@isd-service.de",
		"fax": "0241 9381330",
		"position": {
			"lat": "50.75889",
			"lng": "6.16129"
		},
		"nlCode": "50017",
		"ort": "Aachen",
		"plz": "52078",
		"strasse": "Gewerbepark Brand 70-72",
		"telefon": "0241 938130"
	}, {
		"email": "duesseldorf@isd-service.de",
		"fax": "0211 68784830",
		"position": {
			"lat": "51.25668",
			"lng": "6.80088"
		},
		"nlCode": "50018",
		"ort": "Duesseldorf",
		"plz": "40470",
		"strasse": "Vogelsanger Weg 38",
		"telefon": "0211 6878480"
	}, {
		"email": "erfurt@isd-service.de",
		"fax": "0361 4274830",
		"position": {
			"lat": "50.97745",
			"lng": "11.08604"
		},
		"nlCode": "50019",
		"ort": "Erfurt",
		"plz": "99099",
		"strasse": "Linderbacher Weg 30",
		"telefon": "0361 427480"
	}, {
		"email": "hamburg-lokstedt@isd-service.de",
		"fax": "040 55490930",
		"position": {
			"lat": "53.60491",
			"lng": "9.95539"
		},
		"nlCode": "50020",
		"ort": "Hamburg",
		"plz": "22529",
		"strasse": "Ruetersbarg 48",
		"telefon": "040 5549090"
	}, {
		"email": "wiesbaden@isd-service.de",
		"fax": "0611 7161630",
		"position": {
			"lat": "50.06277",
			"lng": "8.2562"
		},
		"nlCode": "50021",
		"ort": "Wiesbaden",
		"plz": "65189",
		"strasse": "Weidenbornstr. 8a",
		"telefon": "0611 716160"
	}, {
		"email": "wuppertal@isd-service.de",
		"fax": "0202 4086730",
		"position": {
			"lat": "51.23464",
			"lng": "7.15878"
		},
		"nlCode": "50022",
		"ort": "Wuppertal",
		"plz": "42119",
		"strasse": "Vorm Eichholz 2",
		"telefon": "0202 408670"
	}, {
		"email": "bonn@isd-service.de",
		"fax": "0228 3918830",
		"position": {
			"lat": "50.6951",
			"lng": "7.14029"
		},
		"nlCode": "50023",
		"ort": "Bonn",
		"plz": "53175",
		"strasse": "Godesberger Str. 69",
		"telefon": "0228 391880"
	}, {
		"email": "braunschweig@isd-service.de",
		"fax": "0531 2377630",
		"position": {
			"lat": "52.2857",
			"lng": "10.49355"
		},
		"nlCode": "50024",
		"ort": "Braunschweig",
		"plz": "38114",
		"strasse": "Hinter dem Turme 20",
		"telefon": "0531 237760"
	}, {
		"email": "kiel@isd-service.de",
		"fax": "0431 7097830",
		"position": {
			"lat": "54.34862",
			"lng": "10.09145"
		},
		"nlCode": "50025",
		"ort": "Kiel",
		"plz": "24107",
		"strasse": "Suchskrug 10",
		"telefon": "0431 709780"
	}, {
		"email": "nuernberg@isd-service.de",
		"fax": "0911 9377330",
		"position": {
			"lat": "49.47109",
			"lng": "11.0732"
		},
		"nlCode": "50026",
		"ort": "Nuernberg",
		"plz": "90408",
		"strasse": "Obermaierstrasse 16",
		"telefon": "0911 937730"
	}, {
		"email": "muenster@isd-service.de",
		"fax": "0251 2658630",
		"position": {
			"lat": "51.9311",
			"lng": "7.64702"
		},
		"nlCode": "50027",
		"ort": "Muenster",
		"plz": "48155",
		"strasse": "Gustav-Stresemann-Weg 60",
		"telefon": "0251 265860"
	}, {
		"email": "kassel@isd-service.de",
		"fax": "0561 5107630",
		"position": {
			"lat": "51.30845",
			"lng": "9.53901"
		},
		"nlCode": "50028",
		"ort": "Kassel",
		"plz": "34123",
		"strasse": "Osterholzstr. 124",
		"telefon": "0561 510760"
	}, {
		"email": "karlsruhe@isd-service.de",
		"fax": "0721 6632230",
		"position": {
			"lat": "49.04143",
			"lng": "8.32217"
		},
		"nlCode": "50029",
		"ort": "Karlsruhe",
		"plz": "76187",
		"strasse": "Esso Str. 16",
		"telefon": "0721 663220"
	}, {
		"email": "augsburg@isd-service.de",
		"fax": "0821 4483330",
		"position": {
			"lat": "48.36836",
			"lng": "10.93393"
		},
		"nlCode": "50030",
		"ort": "Augsburg",
		"plz": "86165",
		"strasse": "Zugspitzstr. 179",
		"telefon": "0821 448330"
	}, {
		"email": "freiburg@isd-service.de",
		"fax": "0761 5578430",
		"position": {
			"lat": "48.03035",
			"lng": "7.8429"
		},
		"nlCode": "50031",
		"ort": "Freiburg",
		"plz": "79108",
		"strasse": "Tullastr. 84",
		"telefon": "0761 557840"
	}, {
		"email": "siegen@isd-service.de",
		"fax": "0271 4059930",
		"position": {
			"lat": "50.89098",
			"lng": "8.02512"
		},
		"nlCode": "50032",
		"ort": "Siegen",
		"plz": "57076",
		"strasse": "Bismarckstr. 76 A",
		"telefon": "0271 405990"
	}, {
		"email": "gelsenkirchen@isd-service.de",
		"fax": "0209 9571530",
		"position": {
			"lat": "51.49893",
			"lng": "7.07953"
		},
		"nlCode": "50033",
		"ort": "Gelsenkirchen",
		"plz": "45884",
		"strasse": "Schwarzmuehlenstrasse 102",
		"telefon": "0209 957150"
	}, {
		"email": "oldenburg@isd-service.de",
		"fax": "0441 2052630",
		"position": {
			"lat": "53.14677",
			"lng": "8.22259"
		},
		"nlCode": "50034",
		"ort": "Oldenburg",
		"plz": "26123",
		"strasse": "Taastruper Strasse 4",
		"telefon": "0441 205260"
	}, {
		"email": "saarbruecken@isd-service.de",
		"fax": "0681 9582930",
		"position": {
			"lat": "49.22499",
			"lng": "7.02545"
		},
		"nlCode": "50035",
		"ort": "Saarbruecken",
		"plz": "66121",
		"strasse": "Brebacher Landstrasse 9",
		"telefon": "0681 958290"
	}, {
		"email": "moenchengladbach@isd-service.de",
		"fax": "02161 4951730",
		"position": {
			"lat": "51.22076",
			"lng": "6.48446"
		},
		"nlCode": "50036",
		"ort": "Moenchengladbach",
		"plz": "41066",
		"strasse": "Willicher Damm 123",
		"telefon": "02161 495170"
	}, {
		"email": "potsdam@isd-service.de",
		"fax": "0331 70424530",
		"position": {
			"lat": "52.38079",
			"lng": "13.1163"
		},
		"nlCode": "50037",
		"ort": "Potsdam",
		"plz": "14482",
		"strasse": "Wetzlarer Str. 86",
		"telefon": "0331 7042450"
	}, {
		"email": "darmstadt@isd-service.de",
		"fax": "06151 1373330",
		"position": {
			"lat": "49.88681",
			"lng": "8.65587"
		},
		"nlCode": "50038",
		"ort": "Darmstadt",
		"plz": "64289",
		"strasse": "Alsfelder Strasse 11",
		"telefon": "06151 137330"
	}, {
		"email": "luebeck@isd-service.de",
		"fax": "0451 69328730",
		"position": {
			"lat": "53.86311",
			"lng": "10.61556"
		},
		"nlCode": "50039",
		"ort": "Luebeck",
		"plz": "23556",
		"strasse": "Reepschlaegerstr. 10 a",
		"telefon": "0451 6932870"
	}, {
		"email": "muenchen-west@isd-service.de",
		"fax": "089 143476130",
		"position": {
			"lat": "48.13388",
			"lng": "11.52717"
		},
		"nlCode": "50040",
		"ort": "Muenchen",
		"plz": "80686",
		"strasse": "Hansastrasse 17",
		"telefon": "089 14347610"
	}, {
		"email": "chemnitz@isd-service.de",
		"fax": "0371 26211730",
		"position": {
			"lat": "50.82555",
			"lng": "12.83279"
		},
		"nlCode": "50041",
		"ort": "Chemnitz",
		"plz": "09117",
		"strasse": "Oberfrohnaer Strasse 64",
		"telefon": "0371 2621170"
	}, {
		"email": "magdeburg@isd-service.de",
		"fax": "0391 40001930",
		"position": {
			"lat": "52.1161",
			"lng": "11.61328"
		},
		"nlCode": "50042",
		"ort": "Magdeburg",
		"plz": "39112",
		"strasse": "Halberstaedter Strasse 32",
		"telefon": "0391 4000190"
	}, {
		"email": "osnabrueck@isd-service.de",
		"fax": "0541 33019730",
		"position": {
			"lat": "52.27305",
			"lng": "8.06442"
		},
		"nlCode": "50043",
		"ort": "Osnabrueck",
		"plz": "49084",
		"strasse": "Franz-Lenz-Strasse 1 a",
		"telefon": "0541 3301970"
	}, {
		"email": "koblenz@isd-service.de",
		"fax": "0261 98839830",
		"position": {
			"lat": "50.38531",
			"lng": "7.57934"
		},
		"nlCode": "50044",
		"ort": "Koblenz",
		"plz": "56070",
		"strasse": "Carl-Spaeter-Strasse 2 i",
		"telefon": "0261 9883980"
	}, {
		"email": "ulm@isd-service.de",
		"fax": "0731 14058930",
		"position": {
			"lat": "48.40388",
			"lng": "9.95081"
		},
		"nlCode": "50045",
		"ort": "Ulm",
		"plz": "89075",
		"strasse": "In der Wanne 57",
		"telefon": "0731 1405890"
	}, {
		"email": "rostock@isd-service.de",
		"fax": "0381 25274830",
		"position": {
			"lat": "54.12985",
			"lng": "12.0818"
		},
		"nlCode": "50046",
		"ort": "Rostock",
		"plz": "18069",
		"strasse": "Industriestrasse 14",
		"telefon": "0381 2527480"
	}, {
		"email": "regensburg@isd-service.de",
		"fax": "0941 46109630",
		"position": {
			"lat": "49.01005",
			"lng": "12.13967"
		},
		"nlCode": "50047",
		"ort": "Regensburg",
		"plz": "93055",
		"strasse": "Osterhofener Str. 17",
		"telefon": "0941 4610960"
	}, {
		"email": "fulda@isd-service.de",
		"fax": "0661 38008330",
		"position": {
			"lat": "50.53856",
			"lng": "9.68311"
		},
		"nlCode": "50048",
		"ort": "Fulda",
		"plz": "36043",
		"strasse": "Weserstrasse 33",
		"telefon": "0661 3800830"
	}, {
		"email": "schoenefeld@isd-service.de",
		"fax": "030 206066630",
		"position": {
			"lat": "52.36509",
			"lng": "13.56204"
		},
		"nlCode": "50049",
		"ort": "Schoenefeld",
		"plz": "12529",
		"strasse": "Zeppelinstrasse 8",
		"telefon": "030 20606660"
	}, {
		"email": "jena@isd-service.de",
		"fax": "03641 5342030",
		"position": {
			"lat": "50.94765",
			"lng": "11.61274"
		},
		"nlCode": "50050",
		"ort": "Jena",
		"plz": "07749",
		"strasse": "Loebstedter Strasse 107",
		"telefon": "03641 534200"
	}, {
		"email": "norderstedt@isd-service.de",
		"fax": "040 554033330",
		"position": {
			"lat": "53.72742",
			"lng": "10.01893"
		},
		"nlCode": "50051",
		"ort": "Norderstedt",
		"plz": "22844",
		"strasse": "Muehlenweg 131",
		"telefon": "040 55403330"
	}, {
		"email": "heilbronn@isd-service.de",
		"fax": "07131 8738430",
		"position": {
			"lat": "49.11995",
			"lng": "9.21806"
		},
		"nlCode": "50052",
		"ort": "Heilbronn",
		"plz": "74074",
		"strasse": "Ferdinand-Braun-Strasse 3",
		"telefon": "07131 873840"
	}, {
		"email": "wuerzburg@isd-service.de",
		"fax": "0931 20090530",
		"position": {
			"lat": "49.80007",
			"lng": "9.97725"
		},
		"nlCode": "50053",
		"ort": "Wuerzburg",
		"plz": "97076",
		"strasse": "Hertzstrasse 12",
		"telefon": "0931 2009050"
	}, {
		"email": "schwerin@isd-service.de",
		"fax": "0385 61733330",
		"position": {
			"lat": "53.60749",
			"lng": "11.37005"
		},
		"nlCode": "50054",
		"ort": "Schwerin",
		"plz": "19061",
		"strasse": "Otto-Weltzien-Strasse 7",
		"telefon": "0385 6173330"
	}, {
		"email": "paderborn@isd-service.de",
		"fax": "05251 6932430",
		"position": {
			"lat": "51.68253",
			"lng": "8.73819"
		},
		"nlCode": "50055",
		"ort": "Paderborn",
		"plz": "33106",
		"strasse": "Halberstaedter Strasse 83 b",
		"telefon": "05251 693240"
	}, {
		"email": "goettingen@isd-service.de",
		"fax": "0551 70793330",
		"position": {
			"lat": "51.54277",
			"lng": "9.90325"
		},
		"nlCode": "50056",
		"ort": "Goettingen",
		"plz": "37079",
		"strasse": "Rudolf-Winkel-Str. 9",
		"telefon": "0551 7079330"
	}, {
		"email": "giessen@isd-service.de",
		"fax": "0641 97905930",
		"position": {
			"lat": "50.58223",
			"lng": "8.64473"
		},
		"nlCode": "50057",
		"ort": "Giessen",
		"plz": "35398",
		"strasse": "Friedrich-List-Strasse 15",
		"telefon": "0641 9790590"
	}, {
		"email": "bayreuth@isd-service.de",
		"fax": "0921 98009630",
		"position": {
			"lat": "49.93616",
			"lng": "11.61907"
		},
		"nlCode": "50058",
		"ort": "Bayreuth",
		"plz": "95448",
		"strasse": "Orionstr. 17",
		"telefon": "0921 9800960"
	}, {
		"email": "flensburg@isd-service.de",
		"fax": "0461 94027830",
		"position": {
			"lat": "54.76642",
			"lng": "9.42753"
		},
		"nlCode": "50059",
		"ort": "Flensburg",
		"plz": "24941",
		"strasse": "Liebigstrasse 14",
		"telefon": "0461 9402780"
	}, {
		"email": "lueneburg@isd-service.de",
		"fax": "04131 8544430",
		"position": {
			"lat": "53.23589",
			"lng": "10.4614"
		},
		"nlCode": "50060",
		"ort": "Lueneburg",
		"plz": "21337",
		"strasse": "Friedrich-Penseler-Str. 36b",
		"telefon": "04131 854440"
	}, {
		"email": "kempten@isd-service.de",
		"fax": "0831 59033330",
		"position": {
			"lat": "47.71818",
			"lng": "10.32891"
		},
		"nlCode": "50061",
		"ort": "Kempten",
		"plz": "87437",
		"strasse": "Leonhardstrasse 19",
		"telefon": "0831 5903330"
	}, {
		"email": "landshut@isd-service.de",
		"fax": "0871 96564630",
		"position": {
			"lat": "48.52921",
			"lng": "12.14512"
		},
		"nlCode": "50062",
		"ort": "Landshut",
		"plz": "84036",
		"strasse": "Innere Muenchener Strasse 34",
		"telefon": "0871 9656460"
	}, {
		"email": "kaiserslautern@isd-service.de",
		"fax": "0631 89232530",
		"position": {
			"lat": "49.44213",
			"lng": "7.71705"
		},
		"nlCode": "50063",
		"ort": "Kaiserslautern",
		"plz": "67663",
		"strasse": "Merkurstrasse 45",
		"telefon": "0631 8923250"
	}, {
		"email": "reutlingen@isd-service.de",
		"fax": "07121 4879630",
		"position": {
			"lat": "48.50207",
			"lng": "9.21519"
		},
		"nlCode": "50064",
		"ort": "Reutlingen",
		"plz": "72766",
		"strasse": "Am Heilbrunnen 47",
		"telefon": "07121 487960"
	}, {
		"email": "villingen-schwenningen@isd-service.de",
		"fax": "07720 9967930",
		"position": {
			"lat": "48.06011",
			"lng": "8.55205"
		},
		"nlCode": "50065",
		"ort": "Villingen-Schwenningen",
		"plz": "78056",
		"strasse": "Klippeneckstrasse 8",
		"telefon": "07720 996790"
	}, {
		"email": "halle-saale@isd-service.de",
		"fax": "0345 56678730",
		"position": {
			"lat": "51.48367",
			"lng": "12.01611"
		},
		"nlCode": "50066",
		"ort": "Halle (Saale)",
		"plz": "06116",
		"strasse": "Reideburger Strasse 65",
		"telefon": "0345 5667870"
	}, {
		"email": "hagen@isd-service.de",
		"fax": "02331 4842930",
		"position": {
			"lat": "51.35232",
			"lng": "7.44425"
		},
		"nlCode": "50067",
		"ort": "Hagen",
		"plz": "58135",
		"strasse": "Berliner Strasse 11",
		"telefon": "02331 484290"
	}, {
		"email": "neubrandenburg@isd-service.de",
		"fax": "0395 45552630",
		"position": {
			"lat": "53.58169",
			"lng": "13.27142"
		},
		"nlCode": "50068",
		"ort": "Neubrandenburg",
		"plz": "17034",
		"strasse": "Demminer Strasse 61",
		"telefon": "0395 4555260"
	}, {
		"email": "heidelberg@isd-service.de",
		"fax": "06221 9140330",
		"position": {
			"lat": "49.39984",
			"lng": "8.6778"
		},
		"nlCode": "50069",
		"ort": "Heidelberg",
		"plz": "69115",
		"strasse": "Carl-Benz-Strasse 1",
		"telefon": "06221 914030"
	}, {
		"email": "trier@isd-service.de",
		"fax": "0651 99552130",
		"position": {
			"lat": "49.79555",
			"lng": "6.68701"
		},
		"nlCode": "50070",
		"ort": "Trier",
		"plz": "54293",
		"strasse": "Mosaikstrasse 4",
		"telefon": "0651 9955210"
	}, {
		"email": "eisenach@isd-service.de",
		"fax": "03691 8889830",
		"position": {
			"lat": "50.97919",
			"lng": "10.30336"
		},
		"nlCode": "50071",
		"ort": "Eisenach",
		"plz": "99817",
		"strasse": "Kasseler Strasse 29/31",
		"telefon": "03691 888980"
	}, {
		"email": "rosenheim@isd-service.de",
		"fax": "08031 3532330",
		"position": {
			"lat": "47.84922",
			"lng": "12.11655"
		},
		"nlCode": "50072",
		"ort": "Rosenheim",
		"plz": "83026",
		"strasse": "Klepperstrasse 7",
		"telefon": "08031 353230"
	}, {
		"email": "aschaffenburg@isd-service.de",
		"fax": "06021 1506930",
		"position": {
			"lat": "49.98343",
			"lng": "9.11224"
		},
		"nlCode": "50073",
		"ort": "Aschaffenburg",
		"plz": "63741",
		"strasse": "Benzstrasse 2a",
		"telefon": "06021 150690"
	}, {
		"email": "bremerhaven@isd-service.de",
		"fax": "0471 95206230",
		"position": {
			"lat": "53.55067",
			"lng": "8.56649"
		},
		"nlCode": "50074",
		"ort": "Bremerhaven",
		"plz": "27568",
		"strasse": "Schleusenstrasse 3",
		"telefon": "0471 9520620"
	}, {
		"email": "koeln-ost@isd-service.de",
		"fax": "0221 96974330",
		"position": {
			"lat": "50.98305",
			"lng": "7.07521"
		},
		"nlCode": "50075",
		"ort": "Koeln",
		"plz": "51069",
		"strasse": "Waltherstrasse 49-51 (Haus 67)",
		"telefon": "0221 9697430"
	}, {
		"email": "minden@isd-service.de",
		"fax": "0571 64566130",
		"position": {
			"lat": "52.3004",
			"lng": "8.92623"
		},
		"nlCode": "50076",
		"ort": "Minden",
		"plz": "32423",
		"strasse": "Werftstrasse 20",
		"telefon": "0571 6456610"
	}, {
		"email": "cottbus@isd-service.de",
		"fax": "0355 49360930",
		"position": {
			"lat": "51.75511",
			"lng": "14.34053"
		},
		"nlCode": "50077",
		"ort": "Cottbus",
		"plz": "03046",
		"strasse": "Ostrower Damm 2",
		"telefon": "0355 4936090"
	}, {
		"email": "hamm@isd-service.de",
		"fax": "02381 4948330",
		"position": {
			"lat": "51.67659",
			"lng": "7.85291"
		},
		"nlCode": "50078",
		"ort": "Hamm",
		"plz": "59071",
		"strasse": "Herbert-Rust-Weg 6",
		"telefon": "02381 494830"
	}, {
		"email": "ravensburg@isd-service.de",
		"fax": "0751 35978930",
		"position": {
			"lat": "47.78086",
			"lng": "9.60371"
		},
		"nlCode": "50079",
		"ort": "Ravensburg",
		"plz": "88214",
		"strasse": "Schwanenstr. 5",
		"telefon": "0751 3597890"
	}, {
		"email": "neuss@isd-service.de",
		"fax": "02131 2984530",
		"position": {
			"lat": "51.16834",
			"lng": "6.75546"
		},
		"nlCode": "50080",
		"ort": "Neuss",
		"plz": "41468",
		"strasse": "Im Taubental 9",
		"telefon": "02131 298450"
	}, {
		"email": "berlin-ost@isd-service.de",
		"fax": "030 206313430",
		"position": {
			"lat": "52.50441",
			"lng": "13.62532"
		},
		"nlCode": "50081",
		"ort": "Berlin",
		"plz": "12623",
		"strasse": "Landsberger Strasse 263",
		"telefon": "030 20631340"
	}, {
		"email": "bad-homburg@isd-service.de",
		"fax": "06172 1775930",
		"position": {
			"lat": "50.22073",
			"lng": "8.6143"
		},
		"nlCode": "50082",
		"ort": "Bad Homburg",
		"plz": "61348",
		"strasse": "Schaberweg 7",
		"telefon": "06172 177590"
	}, {
		"email": "Bochum@isd-service.de",
		"fax": "0234 32409030",
		"position": {
			"lat": "51.50021",
			"lng": "7.21183"
		},
		"nlCode": "50083",
		"ort": "Bochum",
		"plz": "44809",
		"strasse": "Herner Strasse 299",
		"telefon": "0234 3240900"
	}, {
		"email": "krefeld@isd-service.de",
		"fax": "02151 5241530",
		"position": {
			"lat": "51.32708",
			"lng": "6.59122"
		},
		"nlCode": "50084",
		"ort": "Krefeld",
		"plz": "47805",
		"strasse": "Diessemer Bruch 112b",
		"telefon": "02151 524150"
	}, {
		"email": "esslingen@isd-service.de",
		"fax": "0711 26370830",
		"position": {
			"lat": "48.71303",
			"lng": "9.32899"
		},
		"nlCode": "50085",
		"ort": "Esslingen",
		"plz": "73734",
		"strasse": "Eschbacher Weg 21",
		"telefon": "0711 2637080"
	}, {
		"email": "Pforzheim@isd-service.de",
		"fax": "07231 4247530",
		"position": {
			"lat": "48.90409",
			"lng": "8.65001"
		},
		"nlCode": "50086",
		"ort": "Pforzheim",
		"plz": "75179",
		"strasse": "Heilbronner Strasse 17",
		"telefon": "07231 424750"
	}, {
		"email": "greifswald@isd-service.de",
		"fax": "03834 5193030",
		"position": {
			"lat": "54.08007",
			"lng": "13.48818"
		},
		"nlCode": "50087",
		"ort": "Greifswald",
		"plz": "17493",
		"strasse": "Wolgaster Landstrasse 19",
		"telefon": "03834 519300"
	},
	{
		"email": "aalen@isd-service.de",
		"fax": "07361 5265330",
		"position": {
			"lat": "48.83878",
			"lng": "10.09464"
		},
		"nlCode": "50088",
		"ort": "Aalen",
		"plz": "73430",
		"strasse": "Bahnhofstrasse 7-9",
		"telefon": "07361 526530"
	},
	{
		"email": "bautzen@isd-service.de",
		"fax": "03591 2710330",
		"position": {
			"lat": "51.16726",
			"lng": "14.42228"
		},
		"nlCode": "50089",
		"ort": "Bautzen",
		"plz": "02625",
		"strasse": "Wilthener Strasse 32 (Gebaeude 77)",
		"telefon": "03591 271030"
	}, {
		"email": "ludwigshafen@isd-service.de",
		"fax": "0621 59125430",
		"position": {
			"lat": "49.53583",
			"lng": "8.38708"
		},
		"nlCode": "50090",
		"ort": "Ludwigshafen",
		"plz": "67069",
		"strasse": "Oppauer Str. 131",
		"telefon": "0621 5912540"
	},
	{
		"email": "hildesheim@isd-service.de",
		"fax": "05121",
		"nlCode": "50091",
		"ort": "Hildesheim",
		"plz": "31134",
		"strasse": "Schuhstrasse 33",
		"telefon": "05121",
		"position": {
			"lat": "52.151241348257265",
			"lng": "9.951554004885658"
		}
	}, {
		"email": "garbsen@isd-service.de",
		"fax": "0511 22878630",
		"position": {
			"lat": "52.42939",
			"lng": "9.62424"
		},
		"nlCode": "50092",
		"ort": "Garbsen",
		"plz": "30827",
		"strasse": "Robert-Hesse-Str. 9",
		"telefon": "0511 2287860"
	}
];


export {
	staticMarkers
};
