var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map-container"},[_c('div',{staticClass:"map-content d-flex justify-content-center align-items-center"},[_c('div',{staticClass:"page-footer--seach-box",class:_vm.isOpened ? 'is-opened' : ''},[_c('button',{staticClass:"btn btn--primary page-footer--seach-box-btn",attrs:{"type":"button","aria-label":"Suche Öffnen"},on:{"click":_vm.toggle}},[_c('font-awesome-icon',{attrs:{"icon":[
					'fal',
					'chevron-right'
				]}})],1),_vm._v(" "),_c('div',{staticClass:"page-footer--seach-box-content"},[_c('header',{staticClass:"h5 mb-2"},[_vm._v(_vm._s(_vm.searchBox.header))]),_vm._v(" "),_c('p',[_vm._v(_vm._s(_vm.searchBox.text))]),_vm._v(" "),_c('div',{staticClass:"mb-3 mt-2 page-footer--seach-box-input"},[_c('input',{staticClass:"form-control",attrs:{"type":"text","placeholder":_vm.searchBox.inputPlaceholder,"name":"search_location","id":"search_location_footer"}}),_vm._v(" "),_c('input',{attrs:{"id":"address_footer","name":"address","value":"","type":"hidden","placeholder":""}}),_vm._v(" "),_c('div',{staticClass:"input-group-append"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","onclick":"searchLocation()","aria-label":"Suchen"}},[_c('font-awesome-icon',{attrs:{"id":"button-niederlassungssuche","icon":[
									'isd',
									'standort'
								]}}),_vm._v(" "),_c('span',{staticClass:"visually-hidden"},[_vm._v("\n\t\t\t\t\t\t\t\tNiederlassung Suchen\n\t\t\t\t\t\t\t")])],1)])])])]),_vm._v(" "),_c('gmap-map',{ref:"mapRef",staticClass:"vue-map-container",attrs:{"center":_vm.userLocation,"zoom":10,"options":{
				streetViewControl: false,
				rotateControl: false,
				scrollwheel: false,
				fullscreenControl: false,
				zoomControlOptions: {
					position: 6
				},
			}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }