<template>
	<UnderlyingContentSlider
		:items="items"
		:header="header"
	/>
</template>

<script>
import UnderlyingContentSlider from '~/components/organisms/sliders/ContentSlider';

export default {
	components: {
		UnderlyingContentSlider
	},
	props: {
		header: {
			type: String
		},
		structure: {
			type: Object,
			required: true
		}
	},
	computed: {
		items() {
			let elements;

			this.structure.rows.forEach((row) => {
				row.columns.forEach((column) => {
					if (column.config.colPos == 101) {
						elements = column.elements;
					}
				});
			});

			return elements;
		}
	}
};
</script>
