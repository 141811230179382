<template>
	<UnderlyingImageSlider
		:items="items"
	/>
</template>

<script>
import UnderlyingImageSlider from '~/components/organisms/sliders/ImageSlider';

export default {
	components: {
		UnderlyingImageSlider
	},
	props: {
		structure: {
			type: Object,
			required: true
		}
	},
	computed: {
		items() {
			let elements;

			this.structure.rows.forEach((row) => {
				row.columns.forEach((column) => {
					if (column.config.colPos == 101) {
						elements = column.elements;
					}
				});
			});

			return elements;
		}
	}
};
</script>
