<template>
	<UnderlyingItem
			:items="items"
			:header="header"
	/>
</template>

<script>
import UnderlyingItem from "~/components/molecules/collapsible/GermanymapItem.vue";

export default {
	components: {
		UnderlyingItem
	},
	props: {
		header: {
			type: String
		},
		structure: {
			type: Object,
			required: true
		}
	},
	computed: {
		items() {
			let elements;

			this.structure.rows.forEach((row) => {
				row.columns.forEach((column) => {
					if (column.config.colPos === 101) {
						elements = column.elements;
					}
				});
			});

			return elements;
		}
	}
};
</script>