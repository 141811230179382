<template>
	<highlight-box
		:content="contentElements"
		:highlightbox="higlightbox"
	/>
</template>

<script>
import HighlightBox from '~/components/organisms/highlights/HighlightBox';

export default {
	components: {
		HighlightBox
	},
	props: {
		structure: {
			type: Object,
			required: true
		}
	},
	computed: {
		higlightbox () {
			return {
				highlightboxClasses: this.componentClasses,
				image: this.componentImage,
				mobileImg: this.mobileImage,
				content: this.contentElements,
				contentWidth: this.contentWidth,
				contentStyle: this.contentStyle,
				columnsAnimation: this.columnsAnimation,
				parallaxImage: this.parallaxImage
			};
		},
		config () {
			return this.structure.rows[0].columns[0].config;
		},
		configAnimation () {
			return this.structure.rows[0].columns[0].elements;
		},
		contentElements () {
			let elements;
			this.structure.rows.forEach((row) => {
				row.columns.forEach((column) => {
					if (column.config.colPos == 101) {
						elements = column.elements;
					}
				});
			});
			return elements;
		},
		componentClasses () {
			const PREFIX = 'highlight-box--';
			let classes = [];
			if (this.config) {
				classes.push('')
				if (this.config.includeInSectionNavigation) {
					classes.push('fp-section')
				}
				if (this.config.backgroundImage) {
					classes.push(PREFIX + 'image')
				} else {
					classes.push(PREFIX + this.config.background);
				}
				if (this.config.contentWidth === 'useAvailableSpace') {
					classes.push('fullwidth-container');
				}
				if (this.config.height !== '100vh') {
					classes.push('fp-auto-height')
				}

			}
			return classes;
		},
		componentImage () {
			if (this.config && this.config.backgroundImage) {
				return this.config.backgroundImage.publicUrl;
			}
			return '';
		},
		mobileImage () {
			if (this.structure.mobile_image && this.structure.mobile_image.length) {
				return this.structure.mobile_image[0].publicUrl;
			}
			return '';
		},
		contentAlignment () {
			if (this.config && this.config.contentAlignment) {
				return this.config.contentAlignment.vertical + '-' + this.config.contentAlignment.horizontal;
			}
			return '';
		},
		contentWidth () {
			if (this.config && this.config.contentWidth === 'useAvailableSpace' && this.config.width === '100vw') {
				return 'fullwidth-container--inner';
			}
			return '';
		},
		contentStyle () {
			if (this.config && this.config.contentWidth === 'useAvailableSpace' && this.config.width === '100vw') {
				return {
					'max-width':'100vw'
				};
			}
			return '';
		},
		includeInSectionNavigation () {
			// @TODO: look into section navigation
			if(!!(this.config && this.config.includeInSectionNavigation)) {
				return 'fp-section';
			}
			return '';
		},
		parallaxImage () {
			if(!!(this.config && this.config.parallaxImage)) {
				return 'parallax-img';
			}
			return '';
		},
		columnsAnimation () {
			if (this.configAnimation && this.configAnimation.content_animation) {
				classes.push(this.configAnimation.content_animation)
			}
			return '';
		},
	}
};
</script>
