<template>
	<div class="read-more">
		<div class="read-more-teaser">

			<ce-renderer
				:content="teaser"
			/>

		</div>


			<div
				v-if="hiddenContent.length == 1"
				class="read-more-hidden-content"
			>

				<foldable>
					<ce-renderer
						:content="hiddenContent"
					/>
				</foldable>

			</div>


	</div>
</template>

<script>
import foldable from '~/components/molecules/collapsible/Foldable';

export default {
	name: 'ReadMore',
	components: {
		foldable
	},
	props: {
		structure: {
			type: Object,
			required: true
		}
	},
	computed: {
		teaser() {
			let elements;

			this.structure.rows.forEach((row) => {
				row.columns.forEach((column) => {
					if (column.config.colPos == 101) {
						elements = column.elements;
					}
				});
			});

			return elements;
		},
		hiddenContent() {
			let elements;

			this.structure.rows.forEach((row) => {
				row.columns.forEach((column) => {
					if (column.config.colPos == 102) {
						elements = column.elements;
					}
				});
			});

			return elements;
		}
	}
};
</script>
