<template>
	<div class="tx-kesearch-pi1">
		<form ref="keSearch" @submit.prevent="fetchKeSearch">
			<div class="input-group">
				<input
					v-model="searchQuery"
					type="text"
					class="form-control"
					placeholder="Suchbegriff"
					aria-label="Suchbegriff"
					aria-describedby="button-search"
					id="ke_search_sword"
				>
				<button
					class="btn btn-tertiary"
					type="submit"
					id="button-search"
				>
					<span class="d-none d-md-block">
						{{ButtonText}}
					</span>
				</button>
			</div>
		</form>
	</div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
	props: {
		resultrows: {
			type: Array
		}
	},
	data() {
		return {
			ButtonText: 'Jetzt suchen',
		}
	},
	computed: {
		searchQuery: {
			get() {
				return this.$store.state.searchQueryString;
			},
			set(value) {
				this.$store.dispatch('setSearchQueryString', value);
			},
		},
	},
	methods: {
		...mapActions([
			'fetchKeSearch'
		]),
	},
}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
$tx-kesearch-pi1-button-size: 50px !default;
$tx-kesearch-pi1-icon-size: 30px !default;

	.tx-kesearch-pi1 {
		border: 1px solid $primary;
		border-radius: $border-radius;
		background: #fff;
		padding: rem(10) rem(10) rem(10) rem(15);
		width: 100%;

		@include media-breakpoint-up(xl) {
			width: 50%;
			margin: 0 auto;
		}

		.input-group > :not(:first-child):not(.dropdown-menu) {
			border-radius:$border-radius;
			border: none;

			@include media-breakpoint-down(md) {
				display: flex;
				align-items: center;
				border-radius:100%;
				width: $tx-kesearch-pi1-button-size;
				height: $tx-kesearch-pi1-button-size;
				padding: 10px;

				&:after {
					content: "";
					position: absolute;
					background: url('data:image/svg+xml,#{svg-magnifier($white)}') center center no-repeat;
					width: $tx-kesearch-pi1-icon-size;
					height: $tx-kesearch-pi1-icon-size;
				}
			}
		}

		.input-group > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group > .dropdown-toggle:nth-last-child(n + 3) {
			border-radius:$border-radius;
		}

		.form-control {
			color: $primary;
			font-weight: $font-weight-bold;
			font-size: rem(20);
			box-shadow: none;
			border: 0;
			&:focus {
				box-shadow: none;
			}
		}
	}
</style>
