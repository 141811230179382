<template>
	<div class="content-container">

		<ce-renderer
			:content="contentElements"
		/>

	</div>
</template>

<script>
export default {
	props: {
		structure: {
			type: Object,
			required: true
		}
	},
	computed: {
		contentElements() {
			let elements;

			this.structure.rows.forEach((row) => {
				row.columns.forEach((column) => {
					if (column.config.colPos == 101) {
						elements = column.elements;
					}
				});
			});

			return elements;
		}
	}
};
</script>
