import { render, staticRenderFns } from "./AdventCalendarScript.vue?vue&type=template&id=546d8be4&"
import script from "./AdventCalendarScript.vue?vue&type=script&lang=js&"
export * from "./AdventCalendarScript.vue?vue&type=script&lang=js&"
import style0 from "./AdventCalendarScript.vue?vue&type=style&index=0&id=546d8be4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports