<template>
	<div class="switch mt-2 mb-2">
		<div data-tuerchen_embed data-public_key="ISD-Adventskalender"></div>
	</div>
</template>

<script>
export default {
	mounted() {
		var hash = '64112bb111e4c39db6ea14b02d338986'
		var baseURL = 'https://tuerchen.app/dist'
		var apiURL = 'https://core.tuerchen.com/api'
		var ts = Math.round(Date.now() / 1000 / 60 / 30)

		function injectScriptTag(scriptName) {
			var script = document.createElement('script')
			script.defer = true
			script.src = `${baseURL}/js/${scriptName}.js?${hash}${ts}`
			document.body.appendChild(script)
		}

		window.requestAnimationFrame(function () {
			window.TuerchenConfig = {
				environment: 'prod',
				app_url: 'https://tuerchen.app',
				api_url: apiURL,
				locales: ['en', 'de', 'fr'],
				fallbackLocale: 'en',
				locale: 'de',
				calendarCss: baseURL + `/css/calendar.css?${hash}${ts}`,
				appName: 'TÃ¼rchen.com',
			}
			;['manifest', 'vendor', 'embed'].forEach(injectScriptTag)
		})

	},
}
</script>
<style lang="scss">
.tuerchen-calendar-public-wrapper {
	.calendar-content {
		height: 100vh !important;
	}
}

</style>
