<template>
	<UnderlyingAccordion
		:panels="panels"
	/>
</template>

<script>
import UnderlyingAccordion from '~/components/molecules/collapsible/Accordion';
import ContentContainer from '~/components/organisms/layout/StructuredContent/ContentContainer';

export default {
	name: 'Accordion',
	components: {
		UnderlyingAccordion,
		ContentContainer
	},
	props: {
		structure: {
			type: Object,
			required: true
		}
	},
	computed: {
		panels() {
			const panels = [];

			this.structure.rows.forEach((row) => {
				row.columns.forEach((column) => {
					if (column.config.colPos == 101) {

						column.elements.forEach((container) => {
							if (container.type === 'structured_content' && container.content.structure.layout === 'content_container') {

								// @TODO: It would be nicer, if we could render the ContentContainer at this point somehow.
								// For now, we're just passing the ContentContainer's content elements directly

								panels.push({
									title: container.content.header,
									contentElements: container.content.structure.rows[0].columns[0].elements
								});

							}
						});

					}
				});
			});

			return panels;
		}
	}
};
</script>
