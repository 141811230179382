<template>
	<div class="results">
		<div class="ke_search-result-teaser" ref="keResults">
			<span class="ke_search-result-header">{{ ResultTeaserText }}</span>
			<a href="#" class="small" @click.prevent="clearFilters">
				<div class="ke_search-result-clear">
					<span>{{ FilterText }}</span>
					<font-awesome-icon
						:icon="['fal', 'times']"
					/>
				</div>
			</a>
		</div>
		<div v-if="loadingStatus" class="loading-container">
			<div class="loading"></div>
			Daten werden geladen...
		</div>
		<template v-if="!loadingStatus">
			<div class="search-results">
				<div class="medium mt-4 mb-2 blue" v-if="searchData.numberofresults">
					{{searchData.numberofresults}} Treffer
				</div>
				<template v-if="searchData.resultrows && searchData.resultrows.length">
				<div
					v-for="(resultItem, key) in searchData.resultrows"
					:key="key"
					class="search-result-item"
					:class="resultItem.type"
				>
					<h3
						:inner-html.prop="resultItem.title | replaceApiUrlInHtml()"
						class="result-item-title"
					></h3>

					<div class="result-item-body">
						<!-- <div v-if="resultItem.image" class="result-item-img">
							<img :src="resultItem.filePreviewId" :alt="resultItem.alt">
						</div> -->
						<div class="result-item-text">
							<p
								v-if="resultItem.teaser"
								:inner-html.prop="resultItem.teaser | truncateText(400)"
							></p>
							<nuxt-link
								v-if="resultItem.url"
								:to="resultItem.url | replaceApiUrlToNuxtUrl()"
								class="result-item-url d-flex align-items-center"
							>
								{{resultItem.title_text}}
								<font-awesome-icon
									class="ms-1"
									:icon="['fal', 'long-arrow-right']"
								/>
							</nuxt-link>
						</div>
					</div>
					<hr>
				</div>
				</template>
				<template v-else>
					<b-row class="text-center mt-3">
						<h5>Keine Ergebnisse!</h5>
					</b-row>
				</template>
				<b-pagination
					v-if="searchData.pagebrowser"
					v-model="currentPage"
					:total-rows="keSearchResultsCount"
					:per-page="resultsPerPage"
					aria-controls="results-group"
					align="center"
					first-number
					last-number
				>
				</b-pagination>
				<!-- Debug ! -->
				<div v-if="searchData.errors">
					<div class="ke_search-error">{{searchData.errors.error}}</div>
				</div>

			</div>
		</template>
	</div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
export default {
	data() {
		return {
			ResultTeaserText: 'Passende Suchergebnisse',
			FilterText: 'Suche zurücksetzen'
		}
	},
	computed: {
		...mapState([
			'searchData',
			'keSearchResultsCount',
		]),
		currentPage: {
			get() {
				return this.$store.state.currentPage;
			},
			set(value) {
				this.$store.dispatch('setCurrentPage', value);
				this.fetchKeSearch();
				window.scrollTo({
					top: 350,
					behavior: 'smooth'
				});
			},
		},
		resultsPerPage: {
			get() {
				return this.$store.state.resultsPerPage;
			},
			set(value) {
				this.$store.dispatch('setResultsKeSearchPerPage', value);
			},
		},
		loadingStatus() {
			return this.$store.getters.loadingStatus
		}
	},
	methods: {
		clearFilters() {
			this.$store.dispatch('clearFilters');
		},
		...mapActions([
			'fetchKeSearch'
		]),
	},

}
</script>

<style lang="scss">
@import '~@/assets/scss/common';
.ke_search-result-teaser {
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: row;
	border-bottom: 1px solid $primary;

	.ke_search-result-header {
		@include responsive-type(3, 1.0);
		color: color(primary, base);
		@include media-breakpoint-down(sm) {
			width: 60%;
		}
	}

	.ke_search-result-clear {
		@include responsive-type(0, 1.5);

		@include media-breakpoint-down(md) {
			display: flex;
			flex-direction: column;
			svg {
				display: none;
			}

			span {
				text-align: right;
			}
		}
	}
}

.pagination {
    gap: 10px;
}
.search-result-item {
	padding-top: 1rem;

	.result-item-title {
		@include responsive-type(3, 1.0);
		text-transform: none;
	}

	.hit {
		color: $secondary;
	}

	.result-item-body {
		display: flex;
	}

	.result-item-img {
		max-width: 200px;
		margin-right: 1rem;

		img {
			border-radius: 100%;
			border: 1px solid $isd--grey-dark;
		}

		@include media-breakpoint-down(md) {
			display: none;
		}
	}

	.result-item-url {
		color: color(primary, base);
		font-weight: 700;
	}
}

.loading-container {
	position: relative;
	width: 100%;
	height: 10em;
	top: 0;
	right: 0;
	z-index: 1000;
	padding: 1rem;
	text-align: center;
	color: $primary;
	@include responsive-type(1, 1.0);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.loading {
	display: inline-block;
	width: 1.8rem;
	height: 1.8rem;
	border: 3px solid $tertiary;
	border-radius: 50%;
	border-top-color: #00af84;
	animation: spin 1s ease-in-out infinite;
	margin-bottom: 10px;
}

@keyframes spin {
	to {
		-webkit-transform: rotate(360deg);
	}
}
</style>
