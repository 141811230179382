import {
	capitalize,
	snakeCaseToCamelCase
} from '~/plugins/utilities/StringUtility';

export default {
	name: 'StructuredContent',
	functional: true,
	props: {
		structure: {
			type: [Object, Array],
			required: true
		}
	},
	render (createElement, ctx) {

		const createComponent = () => {
			const structure = ctx.props.structure;

			let componentName = structure.layout;
			if (componentName === null) {
				throw new Error('no structue.layout given!');
			}
			componentName = `Ce${capitalize(snakeCaseToCamelCase(componentName))}`;

			return createElement(
				componentName,
				ctx.data
			);
		};

		return createComponent();
	}
}
